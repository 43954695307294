import {
  Avatar,
  Box,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import Footer from "../../components/ui/Footer";
import InnerPageHeader from "../../components/ui/InnerPageHeader";
import { Helmet } from "react-helmet-async";
import Subnav from "../../components/subnav/index";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BecomeCertified from "../../components/ui/BecomeCertified";

export default function CourseCredits() {
  return (
    <>
      <Helmet>
        <title>Credits CNMAP™</title>
        <meta
          name="description"
          content="This is a brief summary of my page content."
        />
        <link rel="canonical" href="https://example.com/my-page" />
        <meta property="og:title" content="My Page Title" />
        <meta
          property="og:description"
          content="This is a brief summary of my page content."
        />
        <meta
          property="og:image"
          content="https://example.com/my-page-image.jpg"
        />
      </Helmet>

      <Container>
        <Box sx={{ pt: 2, pb: 2 }}>
          <InnerPageHeader
            mainTitle={"Credits Policy"}
            // description={
            //   "CNMAP™ is proud to offer CNEs for qualified nurses to maintain their licensure. More information coming soon"
            // }
            width={"70%"}
          />
        </Box>
      </Container>

      <Box sx={{ backgroundColor: "white" }}>
        <Subnav />
      </Box>

      <Box sx={{ pt: 10 }}>
        <Container>
          <Typography variant="h3">
            Certified Nurse Medical Affairs Professional (CNMAP)
          </Typography>
          <List>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                  <ChevronRightIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Released on 06/19/2023" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "white", color: "#032d3a" }}>
                  <ChevronRightIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Expiration date: 06/18/2026" />
            </ListItem>
          </List>
        </Container>
      </Box>

      <Box sx={{ pt: 10 }}>
        <Container>
          <Typography variant="h3">Program Faculty</Typography>
          <Box sx={{ mt: 3 }}>
            <Typography>
              <b>Janice Nissen, BSN, MBA, MS Population Health</b>
            </Typography>
            <Typography>Independent Consultant</Typography>
            <Typography>
              Accreditation Council for Medical Affairs - Oradell, NJ{" "}
            </Typography>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography>
              <b>Kiana Dixson, PharmD, BCMAS</b>
            </Typography>
            <Typography>Associate Director, Educational Programs</Typography>
            <Typography>
              Accreditation Council for Medical Affairs - Oradell, NJ
            </Typography>
          </Box>
        </Container>
      </Box>

      <Box sx={{ pt: 10 }}>
        <Container>
          <Typography variant="h3">Activity Overview</Typography>
          <Typography sx={{ mt: 2 }}>
            The Certified Nurse Medical Affairs Program (CNMAP™) is a
            self-paced, online, certificate program that provides nurses and
            advanced degree nurses the opportunity to educate themselves in the
            areas of medical affairs and life sciences, and therefore, creating
            a new avenue to career development. The CNMAP™ program enhances
            understanding of the medical affairs landscape within the
            pharmaceutical industry, allowing nursing professionals to power and
            advance their career in this rapidly expanding field.
          </Typography>

          <Box sx={{ mt: 5 }}>
            <Typography variant="h6">
              <b>Program Format</b>
            </Typography>
            <Typography>Web-based on demand</Typography>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography variant="h6">
              <b>Intended Audience</b>
            </Typography>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Nurse or advance practice nurse" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Graduate of relevant degree from an accredited college, university, or institution" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="RN, BSN, MSN, DNP, etc" />
              </ListItem>
            </List>
          </Box>
        </Container>
      </Box>

      <Box sx={{ pt: 10 }}>
        <Container>
          <Typography variant="h3">Learning Objectives</Typography>
          <Accordion sx={{ mt: 4 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Module 2: Biopharmaceutical Industry</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                By the end of this module, you will be able to:
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Identify the global needs driving the growth of the biopharmaceutical industry" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Compare and contrast the different functions within the biopharmaceutical industry, such as Medical Affairs, legal, regulatory, clinical operations, clinical development, and commercial" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Describe the stages involved in drug manufacturing, from research and development to commercial production." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Outline the key stages involved in drug development, from preclinical research to clinical trials." />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Module 3: Device & Diagnostics Industries</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                By the end of this module, you will be able to:
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Define and categorize medical device market segmentation categories and the constraints on them in the U.S. market" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Identify major regulatory activities associated with medical device classification" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Describe the various segments of the diagnostics industry and how it differs from the biopharmaceutical and medical device industries" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Compare and contrast the different types of diagnostic tests" />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Module 4: Drug Development Process</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                By the end of this module, you will be able to:
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Recognize each stage in the drug development process." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Compare and contrast the different types of application required for drug approval, including IND, NDA, and BLA." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Describe how pharmaceutical companies manage a drug’s life cycle after the drug successfully launches the market" />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Module 5: Clinical Trial Design</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                By the end of this module, you will be able to:
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Describe the importance of clinical trials in the drug development process" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Compare and contrast different clinical trial designs and the difference between superiority, inferiority, and equivalence" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Analyze different parameters used in clinical trials" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Recognize various types of sources of bias, and confounding variables in study designs as well as the methods of controlling them" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Identify examples of confounders in clinical trials" />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Module 6: Compliance</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                By the end of this module, you will be able to:
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Describe the regulatory requirements for prescription drug advertisements and promotional labeling" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Discuss on-label and off-label issues regarding approved prescription drugs and the concerns surrounding the dissemination of off-label information" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Identify the issues concerning direct-to-consumer advertising of prescription drugs" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Recognize the role of the compliance team in pharmaceutical organizations in maintaining compliance with FDA laws and regulations" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Interpret the three tiers of compliance in the context of the 21 Code of Federal Regulations" />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Module 7: Regulatory Affairs</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                By the end of this module, you will be able to:
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Describe regulatory affairs for the biopharmaceutical industry" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Identify key international and U.S. regulatory agencies" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Describe the different regulatory application types, including Investigational New Drug (IND), New Drug Application (NDA), and Abbreviated New Drug Application (ANDA)." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Identify key components of the FDA approval and review processes" />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Module 8: Health Economics & Outcomes Research (HEOR)
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                By the end of this module, you will be able to:
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Define models of pharmacoeconomic analysis" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Compare and contrast cost-benefit and cost-effectiveness analyses" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Describe direct and indirect costs and the concept of discounting" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Assess health outcomes assessment studies and the cost effectiveness of drugs" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Describe the steps involved in the analysis of pharmacoeconomic studies" />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Module 9: Overview of Medical Information{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                By the end of this module, you will be able to:
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Describe the role of medical information and its functions, responsibilities, and reporting structure within the overall life sciences organization" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Define the rules related to providing drug information to HCPs" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Identify and differentiate among primary, secondary, and tertiary sources of information" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Describe steps necessary to answer a typical medical information question and how MI responds using standard response documents (SRDs)" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Synthesize perspective on regulatory guidelines and the impact they have on medical information" />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Module 10: Safety & Pharmacovigilance</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                By the end of this module, you will be able to:
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Define pharmacovigilance and its role in ensuring medication safety" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Explain the importance of monitoring, detecting, assessing, and preventing adverse drug reactions (ADRs)" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Evaluate the implications of safety signals for patient safety and regulatory decision-making" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Describe the objectives and components of REMS programs" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Understand the role of clinical trials, adverse event reporting, and post-marketing studies in evaluating drug safety" />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Module 11: Evidence Based Medicine (EBM)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                By the end of this module, you will be able to:
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Define the importance and the applicability of EBM in the modern practice of medicine" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Identify key steps to practice EBM and evaluate the significance of each step in the process" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Distinguish different study designs that are used in EBM" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Compare and contrast different study designs" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Rank the level of evidence-based information and categorize literature resources" />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Module 12: Rules Governing Interactions with HCPs
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                By the end of this module, you will be able to:
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Identify the benefits and potential risks associated with interactions between pharmaceutical companies and HCPs." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Describe the relevant guidelines, regulations, and codes of conduct that govern interactions between pharmaceutical companies and HCPs" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Compare and contrast proactive and reactive discussions" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Familiarize oneself with the guidelines and considerations of involving HCPs in collaboration of advisory boards, drug advertising, and investigator-initiated studies (IIS)" />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Module 14: Roles within the Life Sciences Industry
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                By the end of this module, you will be able to:
              </Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Outline specific roles that nurses typically can assume in the life science industry." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Explain the specific skills required for roles within medical affairs, research and development, commercial, and corporate." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      style={{ backgroundColor: "white", color: "#032d3a" }}
                    >
                      <ChevronRightIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Envision a typical day in the life for roles within medical affairs, research and development, commercial, and corporate." />
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Container>
      </Box>

      <Box sx={{ pt: 10 }}>
        <Container>
          <Typography variant="h3">
            CE Accreditation and Credit Designation Statement
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <b>Nurses</b>
          </Typography>
          <Typography>
            The Institute for Medical and Nursing Education, Inc (IMNE) is
            accredited as a provider of continuing professional development by
            the American Nurses Credentialing Center’s (ANCC’s) Commission on
            Accreditation.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            This educational program provides 15.0 contact hours of continuing
            education credit.
          </Typography>

          <Box sx={{ mt: 3 }}>
            <Typography>
              <b>Disclosures</b>
            </Typography>
            <Typography>
              It is the policy of IMNE to ensure fair balance, independence,
              objectivity, and scientific rigor in all programming. All
              individuals involved in planning (eg, faculty, CME provider staff,
              and educational partner staff) are expected to disclose any
              relevant financial relationships with ineligible companies over
              the past 24 months. It is also required that faculty identify and
              reference off-label products or investigational uses of
              pharmaceutical agents and medical devices.
            </Typography>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography>
              <b>Mitigation of Relevant Financial Interests</b>
            </Typography>
            <Typography>
              IMNE utilizes a peer review process to mitigate potential and
              actual conflicts of interest disclosed by all persons in a
              position to influence the planning process or content development
              of this educational activity. All peer reviewers are without
              relevant financial relationships. A copy of the mitigation
              worksheet is available by request from{" "}
              <a href="mailto:steve.weinman@imne.com">steve.weinman@imne.com</a>
            </Typography>
          </Box>
          <Typography sx={{ mt: 2 }}>
            The following disclosures have been made:
          </Typography>

          <Box sx={{ mt: 3 }}>
            <Typography>
              <b>Faculty & Planning Committee </b>
            </Typography>
            <Typography>
              The following faculty members have nothing to disclose:
            </Typography>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography>ACMA Faculty:</Typography>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Kiana Dixson, PharmD, BCMAS " />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Janice Nissen, BSN, MBA, MS Population Health" />
              </ListItem>
            </List>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography>ANCC Accredited Provider:</Typography>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Steve Weinman, MSc, RN - Program DirectorIMNE" />
              </ListItem>
            </List>
            <Typography>
              <i>
                The following planning committee members have these disclosures:
              </i>
            </Typography>
          </Box>

          <Box sx={{ mt: 6 }}>
            <Typography variant="h3">CNMAP Committee Members:</Typography>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography variant="h6">
                        Michell Davi, DNP, RN, CPNP, AAHIVE
                      </Typography>
                      <Typography sx={{ mt: 1 }}>
                        Disclosure: Dr. Davi has disclosed that she is an
                        employee of Merck.
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography variant="h6">
                        Julia Lanouette, BSN, MS Nursing Innovation
                      </Typography>
                      <Typography sx={{ mt: 1 }}>
                        Disclosure: Ms. Lanouette has disclosed that she is an
                        employee of Moderna.
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography variant="h6">
                        Jessica Saucier, BSN, RN, CCTN, CCRP{" "}
                      </Typography>
                      <Typography sx={{ mt: 1 }}>
                        Disclosure: Ms. Saucier has disclosed that she is an
                        employee of IQVIA.
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography variant="h6">Margaret Weston, BSN</Typography>
                      <Typography sx={{ mt: 1 }}>
                        Disclosure: Ms. Weston has disclosed that she is an
                        employee of Johnson & Johnson.
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            </List>
          </Box>

          <Box sx={{ mt: 6 }}>
            <Typography>
              <b>Disclaimer</b>
            </Typography>
            <Typography>
              This activity is designed for healthcare professionals (HCPs) for
              educational purposes. Information and opinions offered by the
              faculty/presenters represent their own viewpoints. Conclusions
              drawn by the participants should be derived from careful
              consideration of all available scientific information.
            </Typography>
            <Typography sx={{ mt: 2 }}>
              While IMNE makes every effort to have accurate information
              presented, no warranty, expressed or implied, is offered. The
              participant should use his/her clinical judgment, knowledge,
              experience, and diagnostic decision making before applying any
              information, whether provided here or by others, for any
              professional use.
            </Typography>
          </Box>

          <Box sx={{ mt: 6 }}>
            <Typography>
              <b>Method of Participation</b>
            </Typography>
            <Typography>
              To obtain CE credit, each participant should:
            </Typography>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography sx={{ mt: 1 }}>
                        View the content presented
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography sx={{ mt: 1 }}>
                        Answer all questions posed in the post-tests
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography sx={{ mt: 1 }}>
                        Complete and submit the CME/CE demographic and
                        evaluation form at the end of the program
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography sx={{ mt: 1 }}>
                        Download and/or print the online CME/CE certificate
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            </List>
            <Typography sx={{ mt: 2 }}>
              The expiration date for this activity is June 19, 2024. No credit
              will be granted after this date.
            </Typography>
          </Box>

          <Box sx={{ mt: 6 }}>
            <Typography>
              <b>Minimum System Requirements</b>
            </Typography>
            <Typography>
              The following system requirements are recommended for the best
              presentation-viewing experience:
            </Typography>
            <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography sx={{ mt: 1 }}>
                        Microsoft Windows Vista, Windows 7, or Macintosh OS X
                        10.5 (Leopard)
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography sx={{ mt: 1 }}>
                        Display resolution of 1024 × 768 pixels or greater
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography sx={{ mt: 1 }}>
                        Windows-compatible sound card
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography sx={{ mt: 1 }}>
                        Microsoft Internet Explorer 8 or above on PC, Safari
                        version 5 on Mac{" "}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography sx={{ mt: 1 }}>
                        Windows Media Player 11
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography sx={{ mt: 1 }}>Flash Player 11.0</Typography>
                    </>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    style={{ backgroundColor: "white", color: "#032d3a" }}
                  >
                    <ChevronRightIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography sx={{ mt: 1 }}>
                        Broadband Internet connection (512 kbps and above)
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            </List>

            <Typography sx={{ mt: 2 }}>
              <b>Estimated time to complete this activity in its entirety:</b>
            </Typography>
            <Typography>15-20 hours</Typography>
          </Box>

          <Box sx={{ pt: 10 }}>
            <BecomeCertified />
          </Box>
        </Container>
      </Box>

      <Footer />
    </>
  );
}
